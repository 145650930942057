import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crmRoutes from '@domains/CRM/router/CRMRouterMap';

export const USER_INVOICING_TABS = [
  {
    label: {
      title: 'Invoices',
    },
    url: crmRoutes.users.detail.invoicing.invoices,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Payments',
    },
    url: crmRoutes.users.detail.invoicing.payments,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Payment methods',
    },
    url: crmRoutes.users.detail.invoicing.paymentMethods,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Payouts',
    },
    url: crmRoutes.users.detail.invoicing.payouts,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
];
