<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapState } from 'vuex';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  contentFields,
  facets,
} from '../../../../Invoicing/const/paymentsContentCells';

export default {
  name: 'UserPayments',
  components: {
    MuiAlgoliaList,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
    }),
    contentFields() {
      return contentFields(false);
    },
    facets() {
      return facets(this.operatorTimezone);
    },
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.paymentTransactions;
    this.paymentsFilters = `user_fk:${this.user.id}`;
  },
};
</script>
<template>
  <div
    data-test-id="user_payments-view"
    class="UserPayments"
  >
    <h1 class="mb-2">
      Payments
    </h1>
    <MuiAlgoliaList
      :facets="facets"
      :filters="paymentsFilters"
      :export-columns="contentFields"
      :table-config="contentFields"
      :index="algoliaIndex"
      data-test-id="list"
      is-export-enabled
    />
  </div>
</template>
