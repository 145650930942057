import first from 'lodash/first';
import get from 'lodash/get';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { paymentProviderStatusColors } from '@domains/Invoicing/const/PAYMENT_STATUS';
import { FallbackComponent } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { USER_PAYMENT_METHOD_STATUS_COLORS } from '../const';

export function contentCells(operatorTimezone) {
  return [
    {
      title: 'Holder',
      attributeName: 'holder',
      displayPriority: 1,
      minWidth: 110,
      transformValue: sentenceCase,
    },
    {
      title: 'Profile',
      attributeName: 'profiles.type',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: FallbackComponent,
      props: ({ profiles }) => ({
        component: BadgeComponent,
        props: {
          text: sentenceCase(get(first(profiles), 'type', FALLBACK_MESSAGE.dash)),
          color: GRAYSCALE.ground,
        },
        check: first(profiles),
      }),
      minWidth: 110,
    },
    {
      title: 'Added',
      attributeName: 'created_at',
      displayPriority: 1,
      minWidth: 150,
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      title: 'Status',
      attributeName: 'status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: get(USER_PAYMENT_METHOD_STATUS_COLORS, status, GRAYSCALE.ground),
      }),
      minWidth: 125,
    },
    {
      title: 'Payment type',
      attributeName: 'type',
      displayPriority: 1,
      minWidth: 120,
      transformValue: sentenceCase,
    },
    {
      title: 'Provider status',
      attributeName: 'provider_status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: FallbackComponent,
      props: ({ provider_status: providerStatus }) => ({
        component: BadgeComponent,
        props: {
          text: sentenceCase(providerStatus),
          color: get(paymentProviderStatusColors, providerStatus, GRAYSCALE.ground),
        },
        check: providerStatus,
      }),
      minWidth: 130,
    },
    {
      title: 'Expiry',
      attributeName: 'expiry',
      displayPriority: 1,
      minWidth: 80,
    },
    {
      title: 'Default',
      attributeName: 'default',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: FallbackComponent,
      props: paymentMethods => ({
        component: BadgeComponent,
        props: {
          text: (paymentMethods.profiles.length > 0) || get(paymentMethods, 'default') ? 'Default' : FALLBACK_MESSAGE.dash,
          color: COLORS.success,
        },
        check: first(get(paymentMethods, 'profiles')),
      }),
      minWidth: 110,
    },
    {
      title: 'Funding source',
      attributeName: 'funding_source',
      displayPriority: 1,
      minWidth: 130,
    },
    {
      title: 'Number',
      attributeName: 'number',
      displayPriority: 1,
      minWidth: 100,
    },
    {
      title: 'Country',
      attributeName: 'issuer_country_code',
      displayPriority: 0,
      minWidth: 100,
    },
    {
      title: 'Fraud',
      attributeName: 'provider_fraud_score',
      displayPriority: 0,
      minWidth: 100,
    },
    {
      title: 'Provider',
      attributeName: 'provider',
      displayPriority: 0,
      minWidth: 100,
    },
    {
      title: 'Reference number',
      attributeName: 'provider_reference',
      displayPriority: 0,
      minWidth: 100,
    },
    {
      title: 'Alias',
      attributeName: 'alias',
      displayPriority: 0,
      minWidth: 100,
    },
  ];
}
