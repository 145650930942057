<script>
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import API_VERSIONS from '@/constants/apiVersions.const';
import { GenericModalComponent, StoreNotificationComponent } from '@/components';
import { Cancel } from '@/constants/modalButtons.const';
import { PAYMENT_METHODS_OPTIONS } from '../const';
import { USER_SCOPES } from '../../../../store/UsersModule';

export default {
  name: 'UserPaymentMethodForm',

  components: {
    GenericModalComponent,
    MuiInputText,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },

  directives: {
    Validate,
  },

  props: {
    userId: {
      type: Number,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      isFormValid: false,
      inputs: {
        default: 1,
        type: PAYMENT_METHODS_OPTIONS.bankTransfer.value,
        userProfileId: null,
        number: '',
        holder: '',
      },
      paymentMethodOptions: [],
      personalProfileId: null,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      userProfiles: state => map(get(state, 'detail.data.profiles', []), (profile, key) => ({ id: profile.id, type: key })),
      paymentMethodFormStatus: state => state.paymentMethodForm.STATUS,
      userCompanyUuid: state => get(state, 'detail.data.profiles.business.company.uuid'),
    }),

    ...mapState(DOMAINS_MODEL.crm.company, {
      isEmployeePays: state => !get(state, 'company.data.companyPays'),
    }),

    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            disabled: !this.isFormValid,
            loading: this.paymentMethodFormStatus.LOADING,
          },
          text: 'Add',
          listeners: {
            click: () => this.addPaymentMethod(),
          },
        },
      ];
    },

    profileSelected() {
      return this.inputs.userProfileId;
    },

    isBusinessProfileSelected() {
      return this.inputs.userProfileId === this.businessProfileId;
    },

    isSepaPaymentMethodSelected() {
      return this.inputs.type === PAYMENT_METHODS_OPTIONS.sepa.value;
    },
  },

  watch: {
    profileSelected() {
      this.paymentMethodOptions = [
        PAYMENT_METHODS_OPTIONS.bankTransfer,
        ...(this.isBusinessProfileSelected && this.isEmployeePays
          ? [PAYMENT_METHODS_OPTIONS.sepa]
          : []
        ),
      ];

      this.inputs.type = PAYMENT_METHODS_OPTIONS.bankTransfer.value;
    },

    isSepaPaymentMethodSelected() {
      this.clearInputData();
    },
  },

  async created() {
    this.USER_SCOPES = USER_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;

    this.personalProfileId = get(find(this.userProfiles, { type: 'personal' }), 'id');
    this.businessProfileId = get(find(this.userProfiles, { type: 'business' }), 'id');

    this.inputs.userProfileId = this.personalProfileId;

    if (this.userCompanyUuid) {
      await this.getCompanyDetails({ companyUuid: this.userCompanyUuid, version: API_VERSIONS.v2 });
    }
  },

  beforeDestroy() {
    this.resetState({ scopes: [USER_SCOPES.paymentMethodForm] });
  },

  methods: {
    map,
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'postUserPaymentMethod',
    ]),

    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),

    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getCompanyDetails',
    ]),

    async addPaymentMethod() {
      const data = pick(this.inputs, [
        'default',
        'type',
        'userProfileId',
        ...(this.isSepaPaymentMethodSelected ? ['holder', 'number'] : []),
      ]);

      await this.postUserPaymentMethod({
        userId: this.userId,
        data,
      });

      if (!this.paymentMethodFormStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({ message: 'Payment method succesfully <strong>added</strong>' });
      }
    },

    clearInputData() {
      this.inputs = {
        ...this.inputs,
        number: '',
        holder: '',
      };
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    :size="SIZES.small"
    data-test-id="payment_method-form"
    class="UserPaymentMethodForm"
    title="Add payment method"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.paymentMethodForm"
        element="payment method"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
        <p class="emobg-body-1 mb-4">
          The new payment method added to the user will be the active payment method.
        </p>
        <div class="mb-4">
          <MuiSelect
            v-model="inputs.userProfileId"
            v-validate="{
              isRequired: true,
            }"
            :options="map(userProfiles, ({ id, type }) => ({ label: type, value: id }))"
            label="User profile*"
            placeholder="Select"
            class="w-100 text-capitalize"
            name="userProfileId"
          />
        </div>
        <div>
          <MuiSelect
            v-model="inputs.type"
            v-validate="{
              isRequired: true,
            }"
            :options="paymentMethodOptions"
            label="Payment method*"
            class="w-100"
            name="type"
          />
        </div>
        <template v-if="inputs.type === 'SEPA'">
          <div class="my-4">
            <MuiInputText
              v-model="inputs.holder"
              v-validate="{
                isRequired: true,
              }"
              label="Holder*"
              placeholder="Enter the holder name"
              name="holder"
            />
          </div>
          <div>
            <MuiInputText
              v-model="inputs.number"
              v-validate="{
                isRequired: true,
              }"
              label="IBAN*"
              placeholder="Enter the IBAN"
              name="iban"
            />
          </div>
        </template>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
