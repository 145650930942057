var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "UserPayments",
      attrs: { "data-test-id": "user_payments-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Payments ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          facets: _vm.facets,
          filters: _vm.paymentsFilters,
          "export-columns": _vm.contentFields,
          "table-config": _vm.contentFields,
          index: _vm.algoliaIndex,
          "data-test-id": "list",
          "is-export-enabled": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }