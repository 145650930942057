var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "UserInvoicingView",
      attrs: { "data-test-id": "user_invoicing-view" },
    },
    [
      _vm.areTabsReady
        ? _c("PageTabs", {
            attrs: {
              tabs: _vm.tabs,
              "data-test-id": "tabs",
              "are-inner-tabs": "",
            },
          })
        : _vm._e(),
      _c(
        "PageView",
        { attrs: { "is-inner-tab-content": "" } },
        [_c("RouterView")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }