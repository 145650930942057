<script>
import { mapState } from 'vuex';
import capitalize from 'lodash/capitalize';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY, TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import {
  contentCells,
  facets,
} from './config';
import {
  USER_PAYMENT_METHOD_STATUS,
  USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS,
} from './const';
import UserPaymentMethodStatusForm from './components/UserPaymentMethodStatusForm';
import UserPaymentMethodForm from './components/UserPaymentMethodForm';

export default {
  name: 'UserPaymentMethods',
  components: {
    MuiAlgoliaList,
    UserPaymentMethodForm,
    UserPaymentMethodStatusForm,
  },
  data() {
    return {
      isLoading: false,
      isUserPaymentMethodStatusFormOpened: false,
      isUserPaymentMethodFormOpened: false,
      formsBinds: {
        action: null,
        userPaymentMethod: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
    }),
    userPaymentMethodsContentCells() {
      return contentCells(this.operatorTimezone);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.userPaymentMethodFacets = facets();
    this.userPaymentMethodFilters = `user_uuid:${this.user.uuid}`;
    const actionLabelStatus = userPaymentMethod => {
      const action = userPaymentMethod.status === USER_PAYMENT_METHOD_STATUS.active
        ? USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS.deactivate
        : USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS.activate;
      return `${capitalize(action)} payment method`;
    };
    this.userPaymentMethodActions = [
      {
        label: actionLabelStatus,
        method: userPaymentMethod => {
          const action = userPaymentMethod.status === USER_PAYMENT_METHOD_STATUS.active
            ? USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS.deactivate
            : USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS.activate;
          this.isUserPaymentMethodStatusFormOpened = true;
          this.formBinds = {
            userPaymentMethod,
            action,
          };
        },
        isVisible: userPaymentMethod => {
          const actions = [USER_PAYMENT_METHOD_STATUS.active, USER_PAYMENT_METHOD_STATUS.deactivated];
          return actions.includes(userPaymentMethod.status);
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isUserPaymentMethodFormOpened = false;
      this.isUserPaymentMethodStatusFormOpened = false;
      this.formBinds = {
        userPaymentMethod: null,
        action: null,
      };
    },
    onSuccessForm() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.userPaymentMethods, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>
<template>
  <div
    data-test-id="user_payment_methods-view"
    class="UserPayments"
  >
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1>
        Payment methods
      </h1>
      <ui-button
        data-test-id="add_payment_method-button"
        @clickbutton="isUserPaymentMethodFormOpened = true"
      >
        Add payment method
      </ui-button>
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="userPaymentMethods"
        :item-actions="userPaymentMethodActions"
        :facets="userPaymentMethodFacets"
        :filters="userPaymentMethodFilters"
        :export-columns="userPaymentMethodsContentCells"
        :table-config="userPaymentMethodsContentCells"
        :index="ALGOLIA_INDEXES.userPaymentMethod"
        data-test-id="list"
        is-export-enabled
      />
    </div>

    <UserPaymentMethodStatusForm
      v-if="isUserPaymentMethodStatusFormOpened"
      key="statusForm"
      :on-success="onSuccessForm"
      v-bind="formBinds"
      @closeModal="closeModal"
    />
    <UserPaymentMethodForm
      v-if="isUserPaymentMethodFormOpened"
      key="paymentMethodForm"
      :user-id="user.id"
      :on-success="onSuccessForm"
      @closeModal="closeModal"
    />
  </div>
</template>
