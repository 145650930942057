<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DELAY } from '@emobg/web-utils';
import { mapState } from 'vuex';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { contentCells, exportColumns, invoiceFacets } from '../../../../Invoicing/Invoices/List/const/invoicesContentCells';

export default {
  name: 'UserInvoices',
  components: {
    MuiAlgoliaList,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
    }),
    invoiceFacets() {
      return invoiceFacets(this.operatorTimezone);
    },
    contentCells() {
      return contentCells(false);
    },
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.invoices;
    this.exportColumns = exportColumns;
    this.filter = `user_fk:${this.user.id}`;
  },
  methods: {
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.userInvoices, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>
<template>
  <div
    data-test-id="user_invoices-view"
    class="UserInvoices"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Invoices
      </h1>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="userInvoices"
        :table-config="contentCells"
        :filters="filter"
        :facets="invoiceFacets"
        :export-columns="exportColumns"
        :index="algoliaIndex"
        is-export-enabled
        data-test-id="invoice-list"
      />
    </div>
  </div>
</template>
