var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "UserInvoices",
      attrs: { "data-test-id": "user_invoices-view" },
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "userInvoices",
            attrs: {
              "table-config": _vm.contentCells,
              filters: _vm.filter,
              facets: _vm.invoiceFacets,
              "export-columns": _vm.exportColumns,
              index: _vm.algoliaIndex,
              "is-export-enabled": "",
              "data-test-id": "invoice-list",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center mb-2" }, [
      _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Invoices ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }