import { COLORS } from '@emobg/vue-base';

export const USER_PAYMENT_METHOD_STATUS = {
  active: 'active',
  deactivated: 'deactivated',
  refused: 'refused',
  removedByUser: 'removed_by_user',
  watchlisted: 'watchlisted',
  pendingAuthorisation: 'pending_authorisation',
  expired: 'expired',
  frozen: 'frozen',
};

export const USER_PAYMENT_METHOD_STATUS_COLORS = {
  [USER_PAYMENT_METHOD_STATUS.active]: COLORS.success,
  [USER_PAYMENT_METHOD_STATUS.deactivated]: COLORS.warning,
};

export const USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS = {
  activate: 'activate',
  deactivate: 'deactivate',
};

export const PAYMENT_METHODS_OPTIONS = {
  bankTransfer: { label: 'Bank transfer', value: 'bank transfer' },
  sepa: { label: 'SEPA', value: 'SEPA' },
};
