var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UserPaymentMethodForm",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "payment_method-form",
          title: "Add payment method",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.paymentMethodForm,
              element: "payment method",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) } },
            [
              _c("p", { staticClass: "emobg-body-1 mb-4" }, [
                _vm._v(
                  " The new payment method added to the user will be the active payment method. "
                ),
              ]),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n          }",
                      },
                    ],
                    staticClass: "w-100 text-capitalize",
                    attrs: {
                      options: _vm.map(_vm.userProfiles, ({ id, type }) => ({
                        label: type,
                        value: id,
                      })),
                      label: "User profile*",
                      placeholder: "Select",
                      name: "userProfileId",
                    },
                    model: {
                      value: _vm.inputs.userProfileId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "userProfileId", $$v)
                      },
                      expression: "inputs.userProfileId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("MuiSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      options: _vm.paymentMethodOptions,
                      label: "Payment method*",
                      name: "type",
                    },
                    model: {
                      value: _vm.inputs.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "type", $$v)
                      },
                      expression: "inputs.type",
                    },
                  }),
                ],
                1
              ),
              _vm.inputs.type === "SEPA"
                ? [
                    _c(
                      "div",
                      { staticClass: "my-4" },
                      [
                        _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n              isRequired: true,\n            }",
                            },
                          ],
                          attrs: {
                            label: "Holder*",
                            placeholder: "Enter the holder name",
                            name: "holder",
                          },
                          model: {
                            value: _vm.inputs.holder,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "holder", $$v)
                            },
                            expression: "inputs.holder",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n              isRequired: true,\n            }",
                            },
                          ],
                          attrs: {
                            label: "IBAN*",
                            placeholder: "Enter the IBAN",
                            name: "iban",
                          },
                          model: {
                            value: _vm.inputs.number,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "number", $$v)
                            },
                            expression: "inputs.number",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }