var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UserPaymentMethodStatusForm",
        attrs: {
          header: null,
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "user_payment_method_status-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "DeleteModalBodyComponent",
            _vm._b({}, "DeleteModalBodyComponent", _vm.modalContent, false)
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }