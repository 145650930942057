<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { Cancel } from '@/constants/modalButtons.const';
import { DeleteModalBodyComponent, GenericModalComponent } from '@/components';
import { USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS } from '../const';

export default {
  name: 'UserPaymentMethodStatusForm',
  components: {
    GenericModalComponent,
    DeleteModalBodyComponent,
  },
  props: {
    action: {
      type: String,
      required: true,
      validator: value => [
        USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS.activate,
        USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS.deactivate,
      ].includes(value),
    },
    userPaymentMethod: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      paymentMethodStatusStatus: state => state.paymentMethodStatusForm.STATUS,
    }),
    isActivateAction() {
      return this.action === USER_PAYMENT_METHOD_STATUS_FORM_ACTIONS.activate;
    },
    modalContent() {
      const activatePaymentMethod = {
        title: 'Activate this payment method?',
        text: 'This action will activate the payment method.<br>You will be able to deactivate it again.',
      };
      const deactivatePaymentMethod = {
        title: 'Deactivate this payment method?',
        text: 'This action will deactivate the payment method.<br>You will be able to reactivate it again.',
      };
      return this.isActivateAction
        ? activatePaymentMethod
        : deactivatePaymentMethod;
    },
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            loading: this.paymentMethodStatusStatus.LOADING,
          },
          text: this.isActivateAction ? 'Activate' : 'Deactivate',
          listeners: {
            click: () => this.request(),
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'activateUserPaymentMethod',
      'deactivateUserPaymentMethod',
    ]),
    async request() {
      const request = this.isActivateAction ? this.activateUserPaymentMethod : this.deactivateUserPaymentMethod;
      await request({
        userId: this.userPaymentMethod.user_fk,
        paymentMethodId: this.userPaymentMethod.id,
      });

      let type = NOTIFICATION_TYPES.success;
      let message = `Payment method ${this.isActivateAction ? 'activated' : 'deactivated'} succesfully`;
      let textAction = null;

      if (this.paymentMethodStatusStatus.ERROR) {
        type = NOTIFICATION_TYPES.error;
        message = 'Something went wrong, try again.';
        textAction = 'Dismiss';
      } else {
        this.onSuccess();
      }

      this.$notify({
        type,
        message,
        textAction,
      });

      this.$emit('closeModal');
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="null"
    :buttons="modalButtons"
    :size="SIZES.small"
    data-test-id="user_payment_method_status-form"
    class="UserPaymentMethodStatusForm"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent v-bind="modalContent" />
    </template>
  </GenericModalComponent>
</template>
