import { sentenceCase } from '@emobg/web-utils';
export { contentCells } from './contentCells';

export const facets = () => [
  {
    type: 'refinementList',
    props: {
      title: 'Profile',
      attributeName: 'profiles.type',
      transformValue: sentenceCase,
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
      toggled: true,
    },
  },
];
